import { template as template_c63a0b120ae14c9d94aa2a41f7fdff92 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_c63a0b120ae14c9d94aa2a41f7fdff92(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
