import { template as template_359cbb8760d24661903571c01a5a37d4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_359cbb8760d24661903571c01a5a37d4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
