import { template as template_0b247f9951344739a1ed2244e91477df } from "@ember/template-compiler";
const WelcomeHeader = template_0b247f9951344739a1ed2244e91477df(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
